.home-ongoing-projects-container {
    padding-bottom: 200px;
    text-align: center;
    color: white;

    .ongoing-projects-label {
        font-size: 60px;
        font-weight: 600;
    }

    .card-row {
        width: 100%;
        display: flex;
        margin-top: 100px;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .purple-card {
        width: 35%;
        max-width: 600px;
        padding: 50px;
        border-radius: 10px;
        background: linear-gradient(180.33deg, #12012A 13.74%, #A452DD 137.97%);

        .top-container {
            padding-bottom: 30px;

            .card-image {
                max-height: 400px;
                max-width: 100%;
                border-radius: 10px;
            }

            .temporary-image {
                height: 361px;
                width: 100%;
                border-radius: 5px;
                opacity: 0.6;
                background-color: black;
            }
        }

        .bottom-container {

            .card-title {
                font-size: 40px;
                font-weight: 600;
                text-align: left;
            }

            .progress-label {
                padding-top: 10px;
                font-size: 20px;
                text-align: left;
            }

            .tag-container {
                display: flex;
                flex-wrap: wrap;
                padding-top: 20px;
                padding-bottom: 20px;

                .purple-tag {
                    padding: 15px 20px 15px 20px;
                    margin-top: 10px;
                    margin-right: 15px;
                    border-radius: 10px;
                    font-weight: 500;
                    background-color: #AC28F6;
                }

                .blue-tag {
                    padding: 15px 20px 15px 20px;
                    margin-top: 10px;
                    margin-right: 15px;
                    border-radius: 10px;
                    font-weight: 500;
                    background-color: #108CFC;
                }
            }

            .card-description-container {
                padding-right: 50px;

                .card-description {
                    text-align: left;
                    line-height: 30px;
                }
            }

            .card-button-container {
                display: flex;
                padding-top: 25px;

                .center {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    margin-right: auto;

                    .case-study-link {
                        text-decoration: none;

                        .case-study-button {
                            color: white !important;
                        }
                    }
                }
            }
        }
    }

    .blue-card {
        width: 35%;
        max-width: 600px;
        padding: 50px;
        border-radius: 10px;
        background: linear-gradient(180.33deg, #12012A 13.74%, #3F87BC 137.97%);

        .top-container {
            padding-bottom: 30px;

            .card-image {
                max-height: 400px;
                max-width: 100%;
                border-radius: 10px;
            }

            .temporary-image {
                height: 361px;
                width: 100%;
                border-radius: 5px;
                opacity: 0.6;
                background-color: black;
            }
        }

        .bottom-container {

            .card-title {
                font-size: 40px;
                font-weight: 600;
                text-align: left;
            }

            .progress-label {
                padding-top: 10px;
                font-size: 20px;
                text-align: left;
            }

            .tag-container {
                display: flex;
                flex-wrap: wrap;
                padding-top: 20px;
                padding-bottom: 20px;

                .purple-tag {
                    padding: 15px 20px 15px 20px;
                    margin-top: 10px;
                    margin-right: 15px;
                    border-radius: 10px;
                    font-weight: 500;
                    background-color: #AC28F6;
                }

                .blue-tag {
                    padding: 15px 20px 15px 20px;
                    margin-top: 10px;
                    margin-right: 15px;
                    border-radius: 10px;
                    font-weight: 500;
                    background-color: #108CFC;
                }
            }

            .card-description-container {
                padding-right: 50px;

                .card-description {
                    text-align: left;
                    line-height: 30px;
                }
            }

            .card-button-container {
                display: flex;
                padding-top: 25px;

                .center {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    margin-right: auto;

                    .case-study-link {
                        text-decoration: none;

                        .case-study-button {
                            color: white !important;
                        }
                    }
                }
            }
        }
    }
}

// Utility CSS
.right-icon {
    padding-bottom: 5px;
}

// Responsive Screen
@media (max-width: 1400px) {
    .home-ongoing-projects-container {

        .card-row {
            flex-direction: column;

            .purple-card,
            .blue-card {
                width: 70% !important;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .fighters {
            margin-top: 100px;
        }
    }
}

@media (max-width: 500px) {
    .home-ongoing-projects-container {

        .card-row {

            .purple-card,
            .blue-card {
                width: calc(100% - 30px) !important;
                padding: 15px !important;
            }
        }
    }
}