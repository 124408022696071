.home-tools-container {
    padding-bottom: 200px;
    text-align: center;
    color: white;

    .tools-label {
        padding-bottom: 80px;
        font-size: 60px;
        font-weight: 600;
    }

    .design-container {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 100px;

        .design-label {
            text-align: left;
            font-size: 40px;
            font-weight: 600;
        }

        .design-logo-container {
            display: flex;
            justify-content: space-around;
            padding-top: 30px;

            .logo-img {
                max-height: 60px;
            }
        }
    }

    .development-container {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 100px;

        .development-label {
            text-align: left;
            font-size: 40px;
            font-weight: 600;
        }

        .development-logo-container {
            display: flex;
            justify-content: space-around;
            padding-top: 30px;

            .logo-img {
                height: 60px;
                width: 60px;
            }

            .html-logo {
                height: 60px;
                width: 60px;
            }

            .css-logo {
                height: 61px;
                width: 45px;
            }

            .js-logo {
                height: 61px;
                width: 45px;
            }

            .ts-logo {
                height: 60px;
                width: 60px;
            }

            .angular-logo {
                height: 60px;
                width: 60px;
            }

            .flutter-logo {
                // height: 61px;
                width: 49px;
            }
        }
    }
}

// Responsive Screen
@media (max-width: 500px) {
    .home-tools-container {

        .design-container {
            padding-bottom: 0px !important;

            .design-logo-container {
                flex-direction: column;

                .logo-img {
                    // width: 50%;
                    margin-left: auto;
                    margin-right: auto;
                    padding-bottom: 60px;
                }
            }
        }

        .development-logo-container {
            flex-direction: column;

            .logo-img {
                width: 30%;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 40px;
            }
        }
    }
}