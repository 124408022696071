.lm2500-container {
    background-color: #12012A;

    .image-container {
        width: 100%;
        padding-top: 100px;

        .cover-img {
            width: 90%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: 10px;
        }
    }

    .description-container {
        width: 90%;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;

        .description-label {
            font-size: 40px;
            font-weight: 600;
            color: white;
        }

        .description {
            padding-top: 20px;
            line-height: 25px;
            color: white;
        }
    }

    .info-container {
        width: 90%;
        display: flex;
        padding-top: 100px;
        margin-left: auto;
        margin-right: auto;

        .tools-container {
            width: 50%;

            .tools-label {
                padding-bottom: 20px;
                font-size: 40px;
                font-weight: 600;
                color: white;
            }
        }

        .timeline-container {
            width: 50%;

            .timeline-label {
                padding-bottom: 20px;
                font-size: 40px;
                font-weight: 600;
                color: white;
            }

            .timeline {
                font-size: 40px;
                font-weight: 600;
                color: #AC28F6;
            }
        }
    }

    .gallery-container {
        padding-top: 100px;

        .gallery-label {
            font-size: 40px;
            font-weight: 600;
            text-align: center;
            color: white;
        }

        .image-group {

            .sub-gallery-label {
                padding-top: 50px;
                padding-left: 20px;
                font-size: 30px;
                font-weight: 400;
                text-align: center;
                color: white;
            }

            .img-row {
                display: flex;
                justify-content: space-evenly;
                padding-top: 20px;
            }

            .sub-img {
                width: 48%;
                border-radius: 5px;
            }

            .single-img {
                width: 97.3%;
                margin-bottom: 10px;
                border-radius: 5px;
            }
        }
    }

    .threejs {}
}


// Responsive Screen
@media (max-width: 900px) {
    .lm2500-container {

        .info-container {
            flex-direction: column;

            .timeline-container {
                padding-top: 40px;
            }
        }

        .img-row {
            flex-direction: column;
            padding-top: 0px !important;

            .sub-img {
                width: 96% !important;
                margin-top: 10px !important;
                margin-left: auto;
                margin-right: auto;
            }

            .single-img {
                width: 96% !important;
                margin-top: 10px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}