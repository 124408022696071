.home-description-container {
    width: 75%;
    padding-top: 50px;
    padding-bottom: 200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    .self-description {
        width: 50%;
        color: white;

        .description-label {
            font-size: 40px;
            font-weight: 600;
        }

        .description {
            line-height: 25px;
            font-size: 18px;
            font-weight: 300;
        }
    }

    .social-media-container {
        width: 50%;
        color: white;

        .social-media-label {
            font-size: 40px;
            font-weight: 600;
            text-align: center;
        }

        .social-media-logo-container {
            text-align: center;

            .social-media-logo {
                padding: 5px;
                margin-left: 20px;
                margin-right: 20px;
                border-radius: 5px;
                font-size: 50px !important;
                color: white;
            }

            .linkedin {
                background-color: #0A84ff;
            }

            .github {
                background-color: #333;
            }
        }
    }
}

// Responsive Screen
@media (max-width: 900px) {

    .home-description-container {
        flex-direction: column;

        .self-description {
            width: 100% !important;

            .description-label {
                text-align: center;
            }
        }

        .social-media-container {
            width: 100% !important;
        }
    }
}