.home-tldr-container {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    .tldr-title {
        padding-bottom: 50px;
        font-size: 60px;
        font-weight: 600;
        color: white;
    }

    .tldr-body {
        display: flex;
        flex-direction: column;
        // justify-content: space-evenly;

        .border {
            width: 40%;
            padding: 20px 20px 20px 20px;
            margin: 0 auto 20px auto;
            border: 1px solid white;
            border-radius: 10px;

            .title {
                height: 20%;
                padding-bottom: 10px;
                font-size: 20px;
                font-weight: 200;
                color: white;
            }

            .body {
                height: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 40px;
                font-weight: 600;
                color: white;
            }
        }
    }
}

// Responsive Screen
@media (max-width: 720px) {
    .home-tldr-container {

        .border {
            width: 80% !important;
        }
    }
}