.home-introduction-container {
    height: 100vh;
    display: flex;
    background-image: url('../../assets/Home/Landing Page - 1.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .introduction {
        width: 32%;
        padding-top: 20%; //300px;
        margin-left: 10%;

        .introduction-title {
            font-size: 65px;
            font-weight: 600;
            color: white;
        }

        .introduction-description {
            line-height: 25px;
            font-size: 18px;
            font-weight: 300;
            color: white;
        }
    }
}

// Utility CSS
.stylized-blue {
    font-weight: 600;
    color: #0c8bff;
}

.stylized-white {
    font-weight: 600;
}

// Responsive Screen
@media (max-width: 900px) {
    .home-introduction-container {

        .introduction {
            width: 100% !important;
            padding-top: 35% !important;
            margin-right: 10%;
        }
    }
}