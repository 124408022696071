.home-portfolio-container {
    padding-bottom: 200px;
    text-align: center;
    color: white;

    .portfolio-label {
        font-size: 60px;
        font-weight: 600;
    }

    .card {
        max-width: 1200px;
        width: 70%;
        display: flex;
        padding: 50px;
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;

        .left-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .card-title {
                font-size: 40px;
                font-weight: 600;
                text-align: left;
            }

            .tag-container {
                display: flex;
                padding-top: 20px;
                padding-bottom: 20px;

                .tag {
                    padding: 15px 20px 15px 20px;
                    margin-right: 15px;
                    border-radius: 10px;
                    font-weight: 500;
                }
            }

            .card-description-container {
                padding-right: 50px;

                .card-description {
                    text-align: left;
                    line-height: 30px;
                }
            }

            .card-button-container {
                display: flex;
                padding-left: 40px;
                align-items: center;

                .case-study-link {
                    text-decoration: none;

                    .case-study-button {
                        color: white !important;
                    }
                }
            }
        }

        .right-container {
            width: 50%;
            margin-top: auto;
            margin-bottom: auto;

            .card-image {
                max-height: 500px;
                max-width: 100%;
                border-radius: 10px;
            }
        }
    }

    .card:nth-child(even) {
        background: linear-gradient(180.33deg, #12012A 13.74%, #A452DD 137.97%);

        .tag {
            background-color: #AC28F6;
        }
    }

    .card:nth-child(odd) {
        background: linear-gradient(180.33deg, #12012A 13.74%, #3F87BC 137.97%);

        .tag {
            background-color: #108CFC;
        }
    }
}

// Utility CSS
.right-icon {
    padding-bottom: 5px;
}

// Responsive Screen
@media (max-width: 900px) {
    #portfolio {

        .card {
            width: calc(95% - 30px) !important;
            padding: 15px !important;
            flex-direction: column-reverse;

            .left-container,
            .right-container {
                width: 100% !important;
                padding-top: 20px;

                .card-description-container {
                    padding-right: 0px !important;
                }

                .card-button-container {
                    width: 180px;
                    padding-left: 0px !important;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    #portfolio {

        .card {
            width: calc(100% - 30px) !important;
        }
    }
}