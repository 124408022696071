.nav-container {
  width: 100%;
  position: fixed;

  .navbar {
    height: 90px;
    width: 80%;
    padding: 5px 20px 5px 20px;
    display: flex;
    top: 0;
    align-items: center;
    margin: 9px auto 0 auto;
    border-radius: 15px;
    z-index: 70;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.1);

    .links {
      display: flex;
      align-items: center;
      color: white;

      .nav-link {
        padding-bottom: 10px;
        margin-left: 15px;
        margin-right: 15px;
        position: relative;
        top: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0%);
        text-decoration: none;
        font-size: 20px;
        font-weight: 300;
        opacity: 0.7;
        color: white;
      }

      .nav-link:hover {
        border-color: white;
        transition: 0.5s;
      }
    }
  }
}

// Utility CSS
.spacer {
  flex: 1 1 auto;
}

// Responsive Screen
@media (max-width: 900px) {
  .navbar {

    .nav-link {
      font-size: 16px !important;
    }
  }
}