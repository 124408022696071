.enomad-driver-container {
    background-color: #12012A;

    .image-container {
        width: 100%;
        padding-top: 100px;

        .cover-img {
            width: 15%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            border-radius: 10px;
        }
    }

    .description-container {
        width: 90%;
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;

        .description-label {
            font-size: 40px;
            font-weight: 600;
            color: white;
        }

        .description {
            padding-top: 20px;
            line-height: 25px;
            color: white;
        }
    }

    .info-container {
        width: 90%;
        padding-top: 100px;
        margin-left: auto;
        margin-right: auto;

        .horizontal-container {
            display: flex;
            flex-direction: row;

            .tools-container {
                width: 50%;

                .tools-label {
                    padding-bottom: 20px;
                    font-size: 40px;
                    font-weight: 600;
                    color: white;
                }

                .tools-logo-container {
                    width: 70%;
                    display: flex;
                    justify-content: space-between;

                    .flutter-logo {
                        width: 55px;
                    }
                }
            }

            .position-container {
                width: 50%;

                .position-label {
                    padding-bottom: 20px;
                    font-size: 40px;
                    font-weight: 600;
                    color: white;
                }

                .position {
                    font-size: 40px;
                    font-weight: 600;
                    color: #AC28F6;
                }
            }
        }

        .packages-container {
            padding-top: 40px;

            .packages-label {
                padding-bottom: 20px;
                font-size: 40px;
                font-weight: 600;
                color: white;
            }

            .packages {
                font-size: 40px;
                font-weight: 600;
                color: #AC28F6;
            }
        }
    }

    .documentation-container {
        padding-top: 100px;
        padding-bottom: 100px;

        .documentation-label {
            font-size: 40px;
            font-weight: 600;
            text-align: center;
            color: white;
        }

        .image-group {

            .sub-gallery-label {
                padding-top: 50px;
                padding-left: 20px;
                font-size: 30px;
                font-weight: 400;
                text-align: center;
                color: white;
            }

            .img-row {
                display: flex;
                justify-content: space-evenly;
                padding-top: 40px;

                .img-description-container {
                    width: 50%;

                    .sub-img {
                        width: 96%;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        border-radius: 5px;
                    }

                    .sub-description {
                        width: 96%;
                        margin-left: auto;
                        margin-right: auto;
                        padding-top: 10px;
                        text-align: center;
                        color: white;
                    }
                }
            }
        }
    }

    .dashboard-container {
        padding-top: 100px;
        padding-bottom: 100px;

        .dashboard-label {
            font-size: 40px;
            font-weight: 600;
            text-align: center;
            color: white;
        }

        .image-group {

            .sub-gallery-label {
                padding-top: 50px;
                padding-left: 20px;
                font-size: 30px;
                font-weight: 400;
                text-align: center;
                color: white;
            }

            .img-row {
                display: flex;
                justify-content: space-evenly;
                padding-top: 40px;

                .img-description-container {
                    width: 50%;

                    .sub-img {
                        width: 96%;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        border-radius: 5px;
                    }

                    .sub-description {
                        width: 96%;
                        margin-left: auto;
                        margin-right: auto;
                        padding-top: 10px;
                        text-align: center;
                        color: white;
                    }
                }
            }
        }
    }
}


// Responsive Screen
@media (max-width: 1400px) {
    .enomad-driver-container {

        .cover-img {
            width: 25% !important;
        }
    }
}

@media (max-width: 900px) {
    .enomad-driver-container {

        .cover-img {
            width: 40% !important;
        }

        .horizontal-container {
            flex-direction: column !important;

            .tools-container {
                width: 100% !important;

                .tools-logo-container {
                    width: 100% !important;
                }
            }

            .position-container {
                width: 100% !important;
                padding-top: 40px;
            }
        }

        .dashboard-label {
            padding-bottom: 40px;
        }

        .img-row {
            flex-direction: column;
            padding-top: 0px !important;

            .img-description-container {
                width: 100% !important;
                margin-bottom: 40px;
            }
        }
    }
}